import React from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

// const Icon = styled("img")({
//   width: "40px",
//   height: "40px",
//   transition: "transform 0.2s",
//   "&:hover": {
//     transform: "scale(1.2)",
//   },
// });

const IconImg = styled("img")({
  width: "5rem",
  height: "auto",
  filter: "grayscale(100%)",
  transition: "filter 0.2s, transform 0.5s",
  "&:hover": {
    filter: "grayscale(0%)",
    transform: "scale(1.3)",
  },
});

const LargerIconImg = styled(IconImg)({
  width: "7rem", // Increase size for specific icons
});

const DatabaseIcons = () => {
  const databases = [
    { name: "MySQL", icon: "/img/DatabaseIcons/mysql-icon.png" },
    { name: "PostgreSQL", icon: "/img/DatabaseIcons/postgresql-icon.png" },
    { name: "SQLite", icon: "/img/DatabaseIcons/sqlite-icon.png" },
    { name: "MongoDB", icon: "/img/DatabaseIcons/mongodb-icon.png" },
    { name: "Microsoft SQL Server", icon: "/img/DatabaseIcons/mssql-icon.png" },
    { name: "Oracle", icon: "/img/DatabaseIcons/oracle-icon.png" },
    { name: "Redis", icon: "/img/DatabaseIcons/redis-icon.png" },
    { name: "Snowflake", icon: "/img/DatabaseIcons/snow-icon.png" },
    { name: "Cassandra", icon: "/img/DatabaseIcons/cassandra-icon.png" },
    { name: "Elasticsearch", icon: "/img/DatabaseIcons/elastic-icon.png" },
    { name: "IBM DB2", icon: "/img/DatabaseIcons/ibm-icon.png" },
    { name: "Splunk", icon: "/img/DatabaseIcons/splunk-icon.png" },
    { name: "Amazon Dynamo DB", icon: "/img/DatabaseIcons/amazon-icon.png" },
    {
      name: "Google BigQuery",
      icon: "/img/DatabaseIcons/ga-bigquery-icon.png",
    },
    { name: "Google Analytics", icon: "/img/DatabaseIcons/ga-icon.png" },
    { name: "More", icon: null },
  ];

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}
    >
      {databases.map((db, index) => (
        <Grid
          item
          key={index}
          xs={3}
          sm={3}
          md={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {db.icon ? (
            db.name === "Google BigQuery" ||
            db.name === "Amazon Dynamo DB" ||
            db.name === "Google Analytics" ? (
              <LargerIconImg src={db.icon} alt={db.name} />
            ) : (
              <IconImg src={db.icon} alt={db.name} />
            )
          ) : (
            <Typography variant="body1">...and many more</Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DatabaseIcons;
