import React, { useState } from "react";
import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  // border-radius: 15px; // Add rounded corners
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: add a subtle shadow
`;

const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  // border-radius: 15px; // Add rounded corners
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: add a subtle shadow
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.5s ease;
  filter: ${(props) => (props.isHovered ? "none" : "grayscale(100%)")};
  transform: ${(props) =>
    props.isHovered ? "scale(1.25) translateY(-16.66%)" : "scale(1)"};
`;

const ChartsBox = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const images = [
    "/img/HomePage/Charts/Picture1.png",
    "/img/HomePage/Charts/Picture2.png",
    "/img/HomePage/Charts/Picture3.png",
    "/img/HomePage/Charts/Picture4.png",
  ];

  const handleImageClick = (src) => {
    window.open(src, "_blank", "noopener,noreferrer");
  };

  return (
    <GridContainer>
      {images.map((src, index) => (
        <ImageWrapper
          key={index}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleImageClick(src)}
        >
          <Image
            src={src}
            alt={`Chart ${index + 1}`}
            isHovered={hoveredIndex === index}
          />
        </ImageWrapper>
      ))}
    </GridContainer>
  );
};

export default ChartsBox;
