import React from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

const IconImg = styled("img")({
  width: "5rem",
  height: "auto",
  filter: "grayscale(100%)",
  transition: "filter 0.2s, transform 0.5s",
  "&:hover": {
    filter: "grayscale(0%)",
    transform: "scale(1.2)",
  },
});

const LargerIconImg = styled(IconImg)({
  width: "10rem", // Increased size for larger icons
});

const ModelsImages = () => {
  const models = [
    {
      name: "Anthropic",
      icon: "/img/LLMProviders/Anthropic-Logo.png",
      large: false,
      customSize: "4.5rem", // Slightly reduced size for Anthropic
    },
    {
      name: "Google",
      icon: "/img/LLMProviders/Google-Logo.png",
      large: false,
      customSize: "6rem", // Slightly increased size for Google
    },
    { name: "Meta", icon: "/img/LLMProviders/Meta-Logo.png", large: true },
    {
      name: "OpenAI",
      icon: "/img/LLMProviders/openai-logomark.png",
      customSize: "4.25rem", // Slightly increased size for Google
    },
    {
      name: "Mistral AI",
      icon: "/img/LLMProviders/Mistral_AI_Logo.png",
      customSize: "7.75rem", // Slightly increased size for Google
      // large: true,
    },
  ];

  return (
    <Grid
      container
      spacing={3} // Increased spacing between rows
      sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}
    >
      {models.map((model, index) => (
        <Grid
          item
          key={index}
          xs={6}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }} // Added margin bottom for additional spacing
        >
          {model.large ? (
            <LargerIconImg src={model.icon} alt={model.name} />
          ) : (
            <IconImg
              src={model.icon}
              alt={model.name}
              sx={{ width: model.customSize || "5rem" }} // Apply custom size if specified
            />
          )}
        </Grid>
      ))}
      <Grid
        item
        xs={6}
        sm={4}
        sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }} // Added margin top for spacing
      >
        <Typography variant="body1">...and many more</Typography>
      </Grid>
    </Grid>
  );
};

export default ModelsImages;
