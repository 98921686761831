import React, { useState, useEffect, useRef } from "react";
import { Drawer, Avatar, IconButton, Grid, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "axios";
import RazorpayButton from "./RazorpayButton1.js";
import PropTypes from "prop-types";
import { base_URL } from "../apiURLs";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
  },
  navbar: {
    // Additional navbar styles if needed
  },
  profilePic: {
    cursor: "pointer",
    borderRadius: "50%",
    width: 40,
    height: 40,
  },
  sidebar: {
    backgroundColor: "#333",
    color: "#44475b",
    padding: 20,
    opacity: 1,
    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
    fontFamily: "Quicksand, Roboto, Arial, sans-serif",
    display: "flex",
    flexDirection: "column",
  },
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  },
  signInButton: {
    color: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    color: "#44475b",
  },
  userName: {
    marginLeft: "1rem",
  },
  balance: {
    fontSize: "2.5rem",
    margin: 0,
  },
  prompts: {
    fontSize: "2.5rem",
    margin: 0,
  },
  signOutButton: {
    marginTop: "auto",
  },
}));

const UserProfileSideBar = ({
  loggedInUser,
  onSignOut,
  pricePerPrompt,
  userBalance,
  setUserBalance,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:700px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const hasFetchedBalanceRef = useRef(false);

  useEffect(() => {
    // Fetch user balance when the component mounts or user signs in
    const fetchUserBalance = async () => {
      try {
        console.log("Fetching user balance for user:", loggedInUser.userId);
        const response = await axios.get(`${base_URL}/user-balance`, {
          params: { user_id: loggedInUser.userId },
        });

        setUserBalance(response.data.balance);
      } catch (error) {
        console.error("Error fetching user balance:", error);
      }
    };

    if (!hasFetchedBalanceRef.current) {
      fetchUserBalance();
      hasFetchedBalanceRef.current = true;
    }
  }, [loggedInUser.userId, setUserBalance]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleSignOut = () => {
    onSignOut();
    closeSidebar();
  };

  return (
    <div className={classes.container}>
      <nav className={classes.navbar}>
        <Avatar
          sx={{ bgcolor: "#0082fb" }}
          src="/broken-image.jpg"
          // alt={loggedInUser.signInDetails.loginId}
          className={classes.profilePic}
          onClick={toggleSidebar}
        />
      </nav>

      {isSidebarOpen && (
        <div className={classes.backdrop} onClick={closeSidebar}></div>
      )}

      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={closeSidebar}
        classes={{ paper: classes.sidebar }}
        PaperProps={{
          style: {
            width: isMobile ? "auto" : "460px",
            backgroundColor: "#fff",
            borderTopLeftRadius: "0.5rem",
            borderBottomLeftRadius: "0.5rem",
            boxShadow: "rgba(0, 0, 0, 0.1) 0 0 1.5rem 0",
            paddingTop: "1rem",
          },
        }}
      >
        <Grid container direction="column" spacing={2} style={{ flexGrow: 1 }}>
          <Grid item>
            <div className={classes.drawerHeader}>
              <div className={classes.userInfo}>
                <Avatar
                  sx={{ bgcolor: "#0082fb" }}
                  src="/broken-image.jpg"
                  // alt={loggedInUser.signInDetails.loginId}
                  className={classes.profilePic}
                />
                <span
                  className={classes.userName}
                  style={{
                    fontFamily: "Quicksand, Roboto, Arial, sans-serif",
                    fontWeight: "700",
                    fontSize: "1.2rem",
                  }}
                >
                  {loggedInUser.signInDetails.loginId
                    .split("@")[0]
                    .toUpperCase()}
                </span>
              </div>
              <IconButton onClick={closeSidebar}>
                <CloseIcon style={{ color: "#44475b" }} />
              </IconButton>
            </div>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <p style={{ marginBottom: 0 }}>
                  <strong>Email:</strong> {loggedInUser.signInDetails.loginId}
                </p>
              </Grid>
              {/* <Grid item>
                <p style={{ marginBottom: 0 }}>
                  <strong>Phone:</strong> {loggedInUser.signInDetails.loginId}
                </p>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid #ccc",
                margin: "1rem 0",
              }}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p style={{ marginBottom: 0, marginTop: 0 }}>
                  <strong>Topped-up balance</strong>
                </p>
                <p className={classes.balance} style={{ marginTop: 0 }}>
                  <strong>{Math.floor(userBalance)}</strong>{" "}
                  <span style={{ fontSize: "1rem" }}>INR (₹)</span>
                </p>
                <Grid item marginTop={2.5}>
                  <RazorpayButton
                    userBalance={userBalance}
                    setUserBalance={setUserBalance}
                    loggedInUser={loggedInUser}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <p style={{ marginBottom: 0, marginTop: 0 }}>
                  <strong>Available prompts</strong>
                </p>
                <p className={classes.prompts} style={{ marginTop: 0 }}>
                  <strong>{Math.floor(userBalance / pricePerPrompt)}</strong>{" "}
                  <span style={{ fontSize: "1rem" }}> prompts</span>
                </p>
                <p>
                  Price per prompt:
                  <br />
                  <strong>{pricePerPrompt}</strong> INR (₹)
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<LogoutIcon />}
              fullWidth
              style={{
                color: "#44475b",
                borderColor: "#44475b",
                textTransform: "none",
              }}
              onClick={handleSignOut}
            >
              Sign out
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

UserProfileSideBar.propTypes = {
  loggedInUser: PropTypes.shape({
    username: PropTypes.string,
    userId: PropTypes.string,
    signInDetails: PropTypes.shape({
      loginId: PropTypes.string,
      authFlowType: PropTypes.string,
    }),
  }).isRequired,
  onSignOut: PropTypes.func.isRequired,
  pricePerPrompt: PropTypes.number.isRequired,
  userBalance: PropTypes.number.isRequired,
  setUserBalance: PropTypes.func.isRequired,
};

export default UserProfileSideBar;
