import React from "react";
import { Chip, Box } from "@mui/material"; // Added Box component
import PropTypes from "prop-types";
import "./ChipScroll.css"; // Create this CSS file for custom styles

const suggestedQuestions = [
  "What is the latest PE ratio of NIFTY MIDCAP 150 Index?",
  // "Show the closing index values of NIFTY 50 for the month of August 2024.",
  // "Compare the closing index value of NIFTY 50 index with TATAMOTORS close price for the year 2023.",
  "Plot the NAV of Quant Flexicap Fund Direct Plan from January 2023 to July 2023.",
  // "What was the closing value of the NIFTY 50 index on August 1, 2024?",
  // "Calculate and plot the average daily price range (high-low) for each sector during major downturns (e.g., 2008, COVID-19).",
  "Create a scatter plot to show the relationship between P/E ratios and market cap for top 10 companies, by market capitalization, in the automotive sector.",
  "Analyze the lastest operating profit margins for the top 10 companies with the highest P/E ratios.",
  "Plot a column chart of monthly returns of Tata SmallCap Fund Direct Plan from January 2020 to January 2024.",
  // "Create a heat map of monthly returns of NIFTY 50 index from 2020 to 2024. Annotate the heat map with the monthly return values in percentage.",
  "Plot the stock price of TATAMOTORS for the financial year 2024, also plot the NIFTY AUTO index on the same graph. Re-index their prices to 100 as of start date of the financial year 2024.",
  // "Calculate and visualize the average NAV decline for different mutual fund categories during bear markets.",
  "Plot PE ratio of NIFTY 50 index since inception; also plot standard deviation lines for standard deviations above and below the mean, as on current date.",
  "Create pie chart of current shareholding pattern of ZOMATO. In a subplot below the pie chart, also plot a line chart that displays the shareholding pattern over the last 2 years.",
  "Create a scatter plot of the latest current ratio against ROE for top 10 companies by market capitalization in the automobile sector.",
  "Plot the 1 year, 3 year and 5 year and 10 year rolling CAGR (in percentage) of NIFTY 50 index.",
];

const ChipScroll = ({ onSelect }) => {
  // Use suggestedQuestions as chipData
  const chipData = suggestedQuestions.map((label, index) => ({
    key: index + 1,
    label,
  }));

  return (
    <Box // Changed div to Box
      className="chip-scroll-container"
    >
      <Box className="chip-scroll">
        {/** Changed div to Box **/}
        {chipData.map((chip) => (
          <Box key={chip.key} className="chip-wrapper">
            {/** Changed div to Box **/}
            <Chip
              variant="outlined"
              label={chip.label}
              onClick={() => onSelect(chip.label)} // Added onClick event
            />
          </Box>
        ))}
        {/* Duplicate the chips to create the infinite scrolling effect */}
        {chipData.map((chip) => (
          <Box key={chip.key + "-duplicate"} className="chip-wrapper">
            {/** Changed div to Box **/}
            <Chip
              variant="outlined"
              label={chip.label}
              onClick={() => onSelect(chip.label)} // Added onClick event
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

ChipScroll.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default ChipScroll;
