import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MainView from "./views/mainView";
import HomePage from "./views/homePage";
import TermsConditions from "./views/termsConditions";

import "./App.css";

import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
Amplify.configure(config);

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainView />} />
          <Route path="/capital-insights" element={<MainView />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<TermsConditions />} />
          <Route path="/about-us" element={<TermsConditions />} />
          <Route path="/contact-us" element={<TermsConditions />} />
          <Route
            path="/cancellation-refund-policies"
            element={<TermsConditions />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
