/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import ParticleSystem from "./ParticleSystem";
// import SplineMesh from "./SplineMesh";

const BackgroundAnimation = () => {
  const [canvasHeight, setCanvasHeight] = useState("50vh");
  const [canvasTop, setCanvasTop] = useState("0");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1200) {
        setCanvasHeight("80vh");
        setCanvasTop("0");
      } else if (width > 900) {
        setCanvasHeight("60vh");
        setCanvasTop("0");
      } else if (width > 750) {
        setCanvasHeight("60vh");
        setCanvasTop("-5rem");
      } else {
        setCanvasHeight("75vh");
        setCanvasTop("-7.5rem");
      }
    };

    handleResize(); // Set initial height and top
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Canvas
      camera={{ position: [0, 0, 66], fov: 66 }}
      style={{
        position: "absolute",
        top: canvasTop,
        left: "50%", // Center horizontally
        width: "100vw", // Full viewport width
        height: canvasHeight,
        zIndex: -1,
        transform: "translateX(-50%)", // Center the canvas
      }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[100, 100, 100]} />
      <ParticleSystem />
    </Canvas>
  );
};

// const BackgroundAnimation = () => {
//   return (
//     <Canvas
//       camera={{ position: [0, -50, 100], fov: 55 }}
//       style={{
//         width: "100vw",
//         height: "100vh",
//         overflow: "hidden",
//         position: "absolute",
//         top: 0,
//         left: 0,
//         zIndex: -1,
//       }}
//     >
//       <ambientLight intensity={0.5} />
//       <pointLight position={[100, 100, 100]} />
//       <SplineMesh />
//     </Canvas>
//   );
// };

export default BackgroundAnimation;
