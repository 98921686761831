import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "100%",
  [`& .${LinearProgress.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const LoadingBar = ({ open, setLoading, progress, setProgress }) => {
  const progressRef = useRef(progress);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  const incrementProgress = useCallback(
    (step) => {
      let currentProgress = progressRef.current;
      const increment = () => {
        currentProgress = Math.min(currentProgress + step, 100);
        setProgress(currentProgress);
        progressRef.current = currentProgress;

        if (currentProgress < 100) {
          setTimeout(increment, 100);
        }
      };
      increment();
    },
    [setProgress]
  );

  useEffect(() => {
    let timer;
    const startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = (Date.now() - startTime) / 1000;

      // Exponential progress calculation
      const maxTime = 15; // Maximum time for the progress to reach near 100%
      const exponentialProgress = 100 * (1 - Math.exp(-elapsedTime / maxTime));

      // Update progress only if the new value is higher
      if (exponentialProgress > progressRef.current) {
        setProgress(Math.min(exponentialProgress, 98)); // Cap the progress at 98%
      }
    };

    timer = setInterval(updateProgress, 100);

    return () => clearInterval(timer);
  }, [open, incrementProgress, setProgress]);

  useEffect(() => {
    if (!open) {
      const resetTimer = setTimeout(() => {
        setProgress(0);
      }, 1000);
      return () => clearTimeout(resetTimer);
    }
  }, [open, setProgress]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 3,
          backgroundColor: "white",
          borderRadius: 2,
          width: "100%",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setLoading(false)}
          sx={{ position: "absolute", right: 5, top: 5, padding: "0.25rem" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
          Loading<span className="dot-1">.</span>
          <span className="dot-2">.</span>
          <span className="dot-3">.</span>
        </Typography>
        <BorderLinearProgress variant="determinate" value={progress} />
        <Box sx={{ minWidth: 35, mt: 1 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

LoadingBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired,
};

export default React.memo(LoadingBar);
