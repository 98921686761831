import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0082fB",
    },
    text: {
      primary: "#44475b",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          color: "#44475b",
          boxShadow: "1px -1px 14px 0px rgba(0,0,0,0.20)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontFamily: "'Quicksand', 'Roboto', 'Arial', sans-serif",
          fontWeight: 700,
          fontSize: "1.75rem",
        },
        h6: {
          fontSize: "1rem",
          color: "#44475b",
          fontWeight: 700,
          fontFamily: "'Quicksand', 'Roboto', 'Arial', sans-serif",
        },
        h2: {
          fontFamily: "'Quicksand', 'Roboto', 'Arial', sans-serif",
          fontWeight: 900,
          color: "#44475b",
        },
        h10: {
          fontFamily: "'Quicksand', 'Roboto', 'Arial', sans-serif",
          fontWeight: 700,
          fontSize: "1.0625rem",
        },
        instructions: {
          fontWeight: 400,
          color: "#465a69",
          fontFamily: '"Roboto", "Helvetica", "sans-serif"',
          fontSize: "0.8125rem",
          lineHeight: 1.5,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Quicksand', 'Roboto', sans-serif",
          fontWeight: 700,
          borderRadius: "0.5rem",
          color: "#0082fB",
          borderColor: "#0082fB",
          padding: "0.5rem 1rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // "&:hover .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "transparent", // Keep the border color transparent on hover
          // },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0082fb", // Set the border color to #0082fb when focused
            boxShadow: "#fff 0 0 0 0",
          },
        },
        notchedOutline: {
          borderColor: "#DEE3E9",
          borderRadius: "0.5rem", // Set border radius to 0.5rem
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Quicksand', 'Roboto', sans-serif",
          fontWeight: 500,
          fontSize: "0.8125rem",
        },
      },
    },
  },
});

export default theme;
