import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import theme from "../components/theme";
import BackgroundAnimation from "../components/BackgroundAnimation";
import ContactForm from "../components/ContactForm"; // Import the form component
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CssBaseline from "@mui/material/CssBaseline";
import DatabaseIcons from "../components/homePage/DatabaseIcons";
import ModelsImages from "../components/homePage/ModelsImages";
import SecurityBox from "../components/homePage/SecurityBox";
import ChartsBox from "../components/ChartsBox";
import GovernanceImage from "../components/homePage/GovernanceImage";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CustomDrawer from "../components/CustomDrawer"; // Import the new Drawer component
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

function HomePage() {
  const [formOpen, setFormOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer
  const navigate = useNavigate();
  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const isSmallScreen = useMediaQuery("(max-width:500px)");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
        <Toolbar
          sx={{
            "@media (max-width: 900px)": {
              paddingRight: "4.5px",
              paddingLeft: "4.5px",
            },

            "@media (min-width: 1600px)": {
              width: "100%",
              maxWidth: "1600px",
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        >
          {/* Hamburger Menu Icon */}
          <Box
            sx={{
              display: "none", // Hide by default
              "@media (max-width: 699px)": {
                display: "block", // Show when screen width is below 700px
              },
              marginRight: "auto",
            }}
          >
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                // padding: "0",
                marginLeft: "0",
                marginRight: "0",
                color: "#0082fB",
                border: "0.1rem solid #dddee1", // Add a 2px solid border
                borderRadius: "0.5rem", // Rounded corners
                padding: "0.5rem", // Add some padding to give space between icon and border
              }}
            >
              <MenuRoundedIcon fontSize="medium" />
            </IconButton>
          </Box>
          {/* Logo and Company Name */}
          <Box
            className="logo-company_name"
            sx={{
              marginLeft: "0",
              "@media (max-width: 699px)": {
                marginLeft: "2rem",
                marginRight: "auto",
              },
            }}
          >
            <img src="/logo512.png" alt="Logo" className="logo" />
            <Typography variant="h5" component="div" className="company-name">
              agentik
            </Typography>
          </Box>
          <Box
            sx={{
              display: "none", // Hide when screen width is below 700px
              "@media (max-width: 699px)": {
                display: "block", // Show when screen width is 700px or above
              },
            }}
          >
            <IconButton
              color="primary"
              onClick={() => window.open("/capital-insights", "_blank")}
              aria-label="contact sales"
              sx={{
                marginRight: "4.5px",
                border: "0.1rem solid #dddee1", // Add a 2px solid border
                borderRadius: "0.5rem", // Rounded corners
                padding: "0.5rem", // Add some padding to give space between icon and border
              }}
            >
              <InsightsRoundedIcon
                fontSize="medium"
                sx={{
                  color: "#0082fb",
                }}
              />
            </IconButton>

            <IconButton
              color="primary"
              onClick={handleOpenForm}
              aria-label="contact sales"
              sx={{
                paddingRight: "0",
                border: "0.1rem solid #dddee1", // Add a 2px solid border
                borderRadius: "0.5rem", // Rounded corners
                padding: "0.5rem", // Add some padding to give space between icon and border
              }}
            >
              <ForwardToInboxRoundedIcon
                fontSize="medium"
                sx={{
                  color: "#0082fb",
                }}
              />
            </IconButton>
          </Box>
          {/* Navigation Links */}
          <Box
            sx={{
              flexGrow: 1,
              display: "none", // Hide when screen width is below 700px
              "@media (min-width: 700px)": {
                display: "flex", // Show when screen width is 700px or above
              },
              justifyContent: "flex-start",
              marginLeft: "3rem",
              columnGap: "2rem",
              height: "4rem",
              alignItems: "center",
            }}
          >
            <Link to="/home" sx={{ textDecoration: "none" }}>
              <Typography variant="h10" className="nav-btn btn-active">
                Home
              </Typography>
            </Link>
            <Link to="/" sx={{ textDecoration: "none" }}>
              <Typography variant="h10" className="nav-btn">
                Capital Insights
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              display: "none", // Hide when screen width is below 700px
              "@media (min-width: 700px)": {
                display: "flex", // Show when screen width is 700px or above
              },
              gap: "1rem", // Adjust the spacing between the buttons
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ color: "#fff", width: "10rem" }}
              component={Link} // Use Link component
              to="/" // Specify the route
              target="_blank" // Opens the link in a new tab
            >
              Try it now
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleOpenForm}
              sx={{ width: "10rem" }}
            >
              Contact Sales
            </Button>
          </Box>
        </Toolbar>
      </AppBar>{" "}
      <CustomDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        handleOpenForm={handleOpenForm}
      />
      <div style={{ overflow: "hidden" }}>
        <Container
          sx={{
            position: "relative",
            // margin: "1rem 0rem",
            // marginBottom: 0,
            width: "100%",
            "@media (min-width: 1200px)": {
              maxWidth: "100rem", // Maximum width of 100rem
            },
            "@media (min-width: 0px)": {
              paddingLeft: "0", // Make sure this is not overwritten by other properties
              paddingRight: "0",
            },
          }}
        >
          {!isSmallScreen && <BackgroundAnimation />}
          <Grid
            container
            spacing={0}
            paddingTop={"4rem"}
            paddingLeft={{ xs: "1rem", sm: "0" }} // Apply padding only below 600px
            paddingRight={{ xs: "1rem", sm: "0" }} // Apply padding only below 600px
            sx={{ justifyContent: "center" }}
          >
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                "@media (max-width: 900px)": {
                  marginLeft: "4rem",
                  marginRight: "4rem",
                },
                "@media (max-width: 600px)": {
                  marginLeft: "0.25rem",
                  marginRight: "0.25rem",
                },
              }}
            >
              <Typography
                variant="h2"
                component="h1"
                sx={{ marginBottom: "2rem", width: "100%" }}
              >
                Insights at Your Fingertips
              </Typography>
              {/* <Typography variant="h6" component="h1" sx={{ marginTop: "1rem" }}>
              Say goodbye to BRDs, wireframes, backlogs and weeks of wait-time
              for data visualization/analysis!
            </Typography> */}

              <span className="instructions home-pitch-1">
                Say goodbye to BRDs, wireframes, backlogs and long waiting
                times!
                <br />
                Generate interactive charts or get direct answers from your data
                using your own personal AI agent, on demand. <br />
                <br />
                Too good to be true!? Try out our platform yourself for free.
                <br />
                <br />
              </span>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem", // Adjust the spacing between the buttons
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ color: "#fff", width: "10rem" }}
                  component={Link} // Use Link component
                  to="/" // Specify the route
                  target="_blank" // Opens the link in a new tab
                >
                  Try it now
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={handleOpenForm}
                  sx={{ width: "10rem" }}
                >
                  Contact Sales
                </Button>
              </Box>
              <ContactForm open={formOpen} handleClose={handleCloseForm} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            margin={"4rem 1.5rem"}
            padding={"2rem 0"}
            width={"auto"}
            sx={{
              justifyContent: "center",
              borderRadius: "0.5rem",
              boxShadow: "rgba(0, 0, 0, 0.3) 0 0.5rem 2rem",
              backgroundImage: "linear-gradient(to right, #4f31df, #00c6ff)", // Gradient background
              position: "relative", // Allows the SVG to be positioned over this
              overflow: "hidden", // Ensures the SVG background doesn't overflow
              "@media (max-width: 900px)": {
                marginLeft: "4.5px",
                marginRight: "4.5px",
              },
            }}
          >
            <Box
              sx={{
                borderRadius: "0.5rem",
                position: "absolute", // Allows the SVG to be positioned over this
                overflow: "hidden", // Ensures the SVG background doesn't overflow
                left: 0, // Correct property name for positioning
                top: 0, // Correct property name for positioning
                width: "100%",
                height: "100%",
                mixBlendMode: "soft-light",
              }}
            >
              <svg className="card__bg__pattern" width="100%" height="100%">
                <filter
                  id="roughpaper-16917-8305"
                  className="filter-roughpaper"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                >
                  <feTurbulence
                    baseFrequency="0.004"
                    seed="13"
                    result="noise"
                  ></feTurbulence>
                  <feDiffuseLighting
                    in="noise"
                    lightingColor="white"
                    surfaceScale="3.5"
                  >
                    <feDistantLight
                      azimuth="140"
                      elevation="8"
                    ></feDistantLight>
                  </feDiffuseLighting>
                </filter>
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  filter="url(#roughpaper-16917-8305)"
                  fill="none"
                ></rect>
              </svg>
            </Box>
            <Grid
              item
              xs={11}
              sm={10}
              md={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                zIndex: 2, // Ensures the content appears on top of the SVG pattern
              }}
            >
              <Typography variant="h2" component="h2" sx={{ color: "#fff" }}>
                Product
              </Typography>
              <span
                className="instructions home-pitch-1"
                style={{ color: "#fff", marginTop: "1rem" }}
              >
                The Agentik Platform can be tailored to business users across
                functions and organisations across sectors. <br />
              </span>
              <Box
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "0.25rem",
                  borderRadius: "0.5rem",
                  marginTop: "2rem",
                  width: "100%",
                  minHeight: "15rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <video
                  width="100%"
                  height="auto"
                  controls
                  style={{ borderRadius: "0.5rem" }}
                >
                  <source
                    src={`${process.env.PUBLIC_URL}/prod_video/Agentik_filmora project-3.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 0, md: 6 }}
            sx={{
              alignItems: "center",
              margin: {
                xs: "6rem 1rem 3rem 1rem",
                md: "6rem 3rem",
              },
              "@media (max-width: 899px)": {
                flexDirection: "column",
                width: "calc(100% - 2rem)",
                padding: "0",
              },
              "@media (min-width: 900px)": {
                width: "auto !important",
                padding: "0 3rem 0 0",
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                "@media (max-width: 899px)": {
                  textAlign: "center",
                  marginBottom: "3rem",
                  padding: "0 2rem",
                },
              }}
            >
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontSize: "2.5rem" }}
              >
                Speak with your data in plain English
              </Typography>
              <span
                className="instructions home-pitch-1"
                style={{ marginTop: "1rem" }}
              >
                Ask questions or conduct complex analyses <br />
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ChartsBox />
            </Grid>
          </Grid>
          <span className="section-border"></span>
          <Grid
            container
            spacing={{ xs: 0, md: 6 }}
            sx={{
              alignItems: "center",
              margin: {
                xs: "3rem 1rem",
                md: "6rem 3rem",
              },
              "@media (max-width: 899px)": {
                width: "calc(100% - 2rem)",
                flexDirection: "column-reverse",
                padding: "0",
              },
              "@media (min-width: 900px)": {
                width: "auto",
                padding: "0 3rem 0 0",
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
                textAlign: "center",
              }}
            >
              <DatabaseIcons /> {/* Replace with your custom component */}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                "@media (max-width: 899px)": {
                  textAlign: "center",
                  marginBottom: "3rem",
                  padding: "0 2rem",
                },
              }}
            >
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontSize: "2.5rem" }}
              >
                Integrates with all types of data and databases
              </Typography>
              <span
                className="instructions home-pitch-1"
                style={{ marginTop: "1rem" }}
              >
                Plug and play model with minimal setup time <br />
              </span>
            </Grid>
          </Grid>
          <span className="section-border"></span>
          <Grid
            container
            spacing={{ xs: 0, md: 6 }}
            width={"auto"}
            sx={{
              alignItems: "center",
              margin: {
                xs: "3rem 1rem",
                md: "6rem 3rem",
              },
              "@media (max-width: 899px)": {
                width: "calc(100% - 2rem)",
                flexDirection: "column",
                padding: "0",
              },
              "@media (min-width: 900px)": {
                width: "auto",
                padding: "0 3rem 0 0",
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                "@media (max-width: 899px)": {
                  textAlign: "center",
                  marginBottom: "3rem",
                  padding: "0 2rem",
                },
              }}
            >
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontSize: "2.5rem" }}
              >
                Secure and compliant
              </Typography>
              <span
                className="instructions home-pitch-1"
                style={{ marginTop: "1rem" }}
              >
                Deployed on Amazon Web Services (AWS), Agentik is built to the
                highest security standards. No data from your database leaves
                your organisation, you are always in control. <br />
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
                textAlign: "center",
              }}
            >
              <SecurityBox />
            </Grid>
          </Grid>
          <span className="section-border"></span>
          <Grid
            container
            spacing={{ xs: 0, md: 6 }}
            width={"auto"}
            sx={{
              alignItems: "center",
              margin: {
                xs: "3rem 1rem",
                md: "6rem 3rem",
              },
              "@media (max-width: 899px)": {
                width: "calc(100% - 2rem)",
                flexDirection: "column-reverse",
                padding: "0",
              },
              "@media (min-width: 900px)": {
                width: "auto",
                padding: "0 3rem 0 0",
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                "@media (max-width: 899px)": {
                  margin: "0 2rem",
                },
              }}
            >
              <GovernanceImage />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                "@media (max-width: 899px)": {
                  textAlign: "center",
                  marginBottom: "3rem",
                  padding: "0 2rem",
                },
              }}
            >
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontSize: "2.5rem" }}
              >
                Easy governance
              </Typography>
              <span
                className="instructions home-pitch-1"
                style={{ marginTop: "1rem" }}
              >
                Agentik provides unprecedented visibility into your
                organisation&apos;s productivity. Track work product produced on
                the Agentik platform by user, interaction type and more. Access
                fine-grained control over usage and data provenance. <br />
              </span>
            </Grid>
          </Grid>
          <span className="section-border"></span>
          <Grid
            container
            spacing={{ xs: 0, md: 6 }}
            width={"auto"}
            sx={{
              alignItems: "center",
              margin: {
                xs: "3rem 1rem",
                md: "6rem 3rem",
              },
              "@media (max-width: 899px)": {
                width: "calc(100% - 2rem)",
                flexDirection: "column",
                padding: "0",
              },
              "@media (min-width: 900px)": {
                width: "auto",
                padding: "0 3rem 0 0",
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // textAlign: "center",
                "@media (max-width: 899px)": {
                  textAlign: "center",
                  marginBottom: "3rem",
                  padding: "0 2rem",
                },
              }}
            >
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: "2.5rem",
                }}
              >
                Run state-of-the-art models or choose your own
              </Typography>
              <span
                className="instructions home-pitch-1"
                style={{ marginTop: "1rem" }}
              >
                Agents are updated to the latest state-of-the-art AI models from
                leading providers. Alternatively, you can choose which model you
                want to use. <br />
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ModelsImages /> {/* Replace with your custom component */}
            </Grid>
          </Grid>
          <span className="section-border"></span>

          <Grid
            item
            xs={12}
            md={12}
            gap={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "5rem",
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontSize: "2.5rem",
                marginTop: "5rem",
                width: "66%",
                "@media (max-width: 1536px)": {
                  width: "70%",
                },
                "@media (max-width: 1200px)": {
                  width: "80%",
                },
                "@media (max-width: 900px)": {
                  width: "90%",
                },
                "@media (max-width: 600px)": {
                  width: "95%",
                },
              }}
            >
              Join now and be among the leading organisations using AI to solve
              business problems
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem", // Adjust the spacing between the buttons
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ color: "#fff", width: "10rem" }}
                component={Link} // Use Link component
                to="/" // Specify the route
                target="_blank" // Opens the link in a new tab
              >
                Try it now
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleOpenForm}
                sx={{ width: "10rem" }}
              >
                Contact Sales
              </Button>
            </Box>
          </Grid>
          <div className="site-line gradient-transition"></div>

          <Box
            component="footer"
            sx={{
              backgroundColor: "#f5f5f5",
              padding: "3rem 2rem",
              width: "100%",
            }}
          >
            <Container
              sx={{
                width: "100%",
                margin: 0,
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, auto)",
                alignItems: "center",
                columnGap: "5rem",
                rowGap: "2rem",

                "@media (min-width: 1200px)": {
                  maxWidth: "1583px",
                },
                "@media (max-width: 699px)": {
                  gridTemplateColumns: "1fr",
                },
              }}
            >
              {/* Logo and Company Name */}
              <Grid item xs={12} sm={5} md={5}>
                <Box
                  display="flex"
                  alignItems="center"
                  mb={2}
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img
                    src="/logo512.png"
                    alt="Logo"
                    style={{
                      width: "4rem",
                      marginRight: "1rem",
                    }}
                  />
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      color: "#0082fb",
                      fontFamily: "Quicksand",
                      fontWeight: 600,
                    }}
                  >
                    agentik
                  </Typography>
                </Box>
              </Grid>

              {/* Tagline */}
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2rem",
                    color: "#44475b",
                    fontWeight: 400,
                    fontFamily: "'Quicksand', 'Roboto', 'Arial', sans-serif",
                  }}
                >
                  The Trusted AI Partner for Data-driven Organisations.
                </Typography>
              </Grid>

              {/* Social Media and Copyright */}
              <Grid item xs={12} sm={5} md={5} height={"100%"}>
                {/* <Typography variant="h6" gutterBottom>
                  Connect With Us
                </Typography> */}
                <Box display="flex" alignItems="center" mb={2}>
                  <Typography variant="body2" sx={{ marginRight: "0.25rem" }}>
                    Follow us on:
                  </Typography>
                  <IconButton
                    href="https://www.linkedin.com/company/agentik-in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#0072b1", padding: 0 }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </Box>
                <Typography variant="body2">
                  Email us at:{" "}
                  <a
                    href="mailto:sales@agentik.in"
                    style={{ color: "#0072b1", textDecoration: "none" }}
                  >
                    sales@agentik.in
                  </a>
                </Typography>
                <Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ paddingTop: "1rem" }}
                  >
                    Copyright © {new Date().getFullYear()} Agentik. All rights
                    reserved.
                  </Typography>
                </Box>
              </Grid>
              {/* Quick Links */}
              <Grid item xs={12} sm={5} md={5}>
                {/* <Typography variant="h6" gutterBottom>
                  Quick Links
                </Typography> */}
                <Grid container spacing={2}>
                  {[
                    { text: "About Us", path: "/about-us" },
                    { text: "Contact Us", path: "/contact-us" },
                    { text: "Privacy Policy", path: "/privacy-policy" },
                    { text: "Terms & Conditions", path: "/terms-conditions" },
                    {
                      text: "Cancellation/Refund Policies",
                      path: "/cancellation-refund-policies",
                    },
                  ].map(({ text, path }, index) => (
                    <Grid item xs={6} key={text}>
                      <Link
                        to={path}
                        style={{
                          color: "#44475b",
                          textDecoration: "none",
                          display: "block",
                        }}
                      >
                        <Typography variant="body2">{text}</Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default HomePage;
