/* eslint-disable react/no-unknown-property */
import React, { useRef, useState, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const ParticleSystem = () => {
  const particles = useRef();
  const color3 = new THREE.Color("rgb(0,71,91)");
  const [sphereSize, setSphereSize] = useState(48);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 750) {
        setSphereSize(34);
      } else if (window.innerWidth < 900) {
        setSphereSize(40);
      } else {
        setSphereSize(48);
      }
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useFrame(() => {
    if (particles.current) {
      particles.current.rotation.y += 0.001;
    }
  });

  return (
    <points ref={particles}>
      <sphereGeometry args={[sphereSize, sphereSize, sphereSize]} />
      <pointsMaterial
        size={0.5}
        color={color3}
        opacity={0.5}
        transparent
        depthWrite={false}
        blending={THREE.AdditiveBlending}
      />
    </points>
  );
};

export default ParticleSystem;

// Function to create a circular texture
// const createCircleTexture = () => {
//   const size = 64; // Size of the texture
//   const canvas = document.createElement('canvas');
//   canvas.width = size;
//   canvas.height = size;
//   const ctx = canvas.getContext('2d');

//   // Draw a filled circle
//   ctx.beginPath();
//   ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
//   ctx.fillStyle = 'white'; // Color of the circle
//   ctx.fill();

//   return new THREE.CanvasTexture(canvas);
// };

// const ParticleSystem = () => {
//   const particles = useRef();
//   const color3 = new THREE.Color("rgb(0,71,91)");
//   const texture = createCircleTexture(); // Create circular texture

//   useFrame(() => {
//     if (particles.current) {
//       particles.current.rotation.y += 0.001;
//     }
//   });

//   return (
//     <points ref={particles}>
//       <sphereGeometry args={[48, 48, 48]} />
//       <pointsMaterial
//         size={0.5}
//         color={color3}
//         opacity={0.5}
//         transparent
//         depthWrite={false}
//         blending={THREE.AdditiveBlending}
//         map={texture}  // Apply the circular texture
//         sizeAttenuation={true} // Ensure points scale with distance
//         alphaTest={0.5} // Discard pixels with alpha below 0.5
//       />
//     </points>
//   );
// };

// export default ParticleSystem;
