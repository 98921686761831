import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  Box,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import theme from "../components/theme";
import ContactForm from "../components/ContactForm";

const TermsConditions = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [contactFormOpen, setContactFormOpen] = React.useState(false); // State to control ContactForm

  useEffect(() => {
    const pathToTabIndex = {
      "/about-us": 0,
      "/contact-us": 1,
      "/privacy-policy": 2,
      "/terms-conditions": 3,
      "/cancellation-refund-policies": 4,
    };
    setValue(pathToTabIndex[location.pathname] || 0);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleContactFormOpen = () => {
    setContactFormOpen(true);
  };

  const handleContactFormClose = () => {
    setContactFormOpen(false);
  };

  // <p>For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean MEGHRAJ VIJAY PANMAND, whose registered/operational office is A/P- Kohinde Bk., Taluka- Khed, District- Pune Pune MAHARASHTRA 412404 . "you", "your", "user", "visitor" shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.</p>

  const termsContent = `
    <h2>Terms and Conditions</h2>
    <p>Last updated on Aug 27 2024</p>

    <p>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</p>

    <ul>
      <li>The content of the pages of this website is subject to change without notice.</li>
      <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
      <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</li>
      <li>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
      <li>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
      <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>
      <li>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
      <li>You may not create a link to our website from another website or document without our prior written consent.</li>
      <li>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India.</li>
      <li>We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time</li>
    </ul>
  `;

  // <p>Operational address: Meghraj Panmand, A/P- Kohinde Bk., Taluka- Khed, District- Pune, MAHARASHTRA 412404</p>

  const cancellationRefundContent = `
    <h2>Cancellation and Refund Policy</h2>
    <p>No cancellation or refund.</p>
  `;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <Box className="logo-company_name" sx={{ marginLeft: "2rem" }}>
            <img src="/logo512.png" alt="Logo" className="logo" />
            <Typography variant="h5" component="div" className="company-name">
              agentik
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "3rem",
            }}
          >
            <Link to="/home" style={{ textDecoration: "none" }}>
              <Typography variant="h10" className="nav-btn">
                Home
              </Typography>
            </Link>
            <Link to="/" style={{ textDecoration: "none", marginLeft: "2rem" }}>
              <Typography variant="h10" className="nav-btn">
                Capital Insights
              </Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="About Us" component={Link} to="/about-us" />
          <Tab label="Contact Us" onClick={handleContactFormOpen} />
          <Tab label="Privacy Policy" component={Link} to="/privacy-policy" />
          <Tab
            label="Terms & Conditions"
            component={Link}
            to="/terms-conditions"
          />
          <Tab
            label="Cancellation/Refund Policies"
            component={Link}
            to="/cancellation-refund-policies"
          />
        </Tabs>

        <Box sx={{ p: 3 }}>
          {value === 0 && (
            <Typography>
              Agentik is a technology company focused on practical applications
              of Al to solve problems, enhance products and optimize processes.
              <br />
              We enable organisations generate insights from their data, on
              demand. Our proprietary plug and play AI agents can analyse both
              structured and unstructured data.
            </Typography>
          )}
          {value === 1 && (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleContactFormOpen}
                  sx={{ color: "#fff" }}
                >
                  Open Contact Form
                </Button>
              </Box>
              <ContactForm
                open={contactFormOpen}
                handleClose={handleContactFormClose}
              />
            </>
          )}
          {value === 2 && (
            <Typography>
              <h2>Privacy Policy</h2>

              <p>Last updated: 13 Sep 2024</p>

              <p>
                Agentik (&ldquo;we&rdquo;, &ldquo;our&rdquo;, or
                &ldquo;us&rdquo;) is committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, disclose, and
                safeguard your information when you use our platform.
              </p>

              <h3>1. Information We Collect</h3>

              <p>
                We collect information that you provide directly to us, such as:
              </p>
              <ul>
                <li>Account information (e.g., email address, password)</li>
                <li>Payment information</li>
                <li>
                  Usage data (e.g., queries, interactions with our platform)
                </li>
                <li>
                  Communication data (e.g., when you contact our support team)
                </li>
              </ul>

              <h3>2. How We Use Your Information</h3>

              <p>We use your information to:</p>
              <ul>
                <li>Provide, maintain, and improve our services</li>
                <li>Process transactions and send related information</li>
                <li>Respond to your comments, questions, and requests</li>
                <li>
                  Send you technical notices, updates, security alerts, and
                  support messages
                </li>
                <li>
                  Monitor and analyze trends, usage, and activities in
                  connection with our services
                </li>
              </ul>

              <h3>3. Data Sharing and Disclosure</h3>

              <p>We may share your information with:</p>
              <ul>
                <li>Service providers who perform services on our behalf</li>
                <li>
                  Professional advisors, such as lawyers, auditors, and insurers
                </li>
                <li>
                  Government bodies or law enforcement agencies, in response to
                  a verified request relating to a criminal investigation or
                  alleged illegal activity
                </li>
              </ul>

              <h3>4. Data Security</h3>

              <p>
                We implement appropriate technical and organizational measures
                to protect your information against unauthorized or unlawful
                processing, accidental loss, destruction, or damage.
              </p>

              <h3>5. Your Rights</h3>

              <p>
                Depending on your location, you may have certain rights
                regarding your personal information, such as the right to
                access, correct, or delete your data.
              </p>

              <h3>6. Changes to This Privacy Policy</h3>

              <p>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>

              <h3>7. Contact Us</h3>

              <p>
                If you have any questions about this Privacy Policy, please
                contact us at support@agentik.in.
              </p>
            </Typography>
          )}
          {value === 3 && (
            <Typography dangerouslySetInnerHTML={{ __html: termsContent }} />
          )}
          {value === 4 && (
            <Typography
              dangerouslySetInnerHTML={{ __html: cancellationRefundContent }}
            />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TermsConditions;
