import React from "react";
import { Box, Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import { styled } from "@mui/system";

const HoverBox = styled(Box)({
  width: "10rem",
  height: "12.5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between", // Pushes the Typography to the bottom
  textAlign: "center",
  color: "gray",
  transition: "transform 0.5s, filter 0.3s, color 0.3s",
  filter: "grayscale(100%)",
  "&:hover": {
    transform: "scale(1.1)",
    filter: "grayscale(0%)",
    color: "#0082fb", // Changes the text color and icon fill on hover
  },
  cursor: "pointer",
  textDecoration: "none",
  borderRadius: "0.5rem",
  border: "0.15rem solid #e5e7eb",
  padding: "0.25rem", // Optional: Add padding to ensure there's space around the content
  boxSizing: "border-box", // Ensure padding and border are included in the element's total width and height
  marginRight: "5rem", // Add gap between boxes
  // Remove margin on the last item to avoid extra space
  "&:last-child": {
    marginRight: 0,
  },
});

const HoverBoxContainer = styled(Box)({
  display: "flex",
  gap: "1rem", // Adds gap between HoverBox components
});

const SvgLogo = styled("img")({
  width: "4rem",
  height: "auto",
  fill: "currentColor",
});

const SecurityBox = () => {
  return (
    <HoverBoxContainer>
      <HoverBox
        component="a"
        href="https://aws.amazon.com/compliance/soc-faqs/"
        target="_blank"
      >
        <Box flex="1" display="flex" alignItems="center">
          <SecurityIcon
            fontSize="large"
            sx={{ fontSize: "4rem", fill: "currentColor" }}
          />
        </Box>
        <Typography
          variant="h6"
          mt={1}
          sx={{
            backgroundColor: "#e5e7eb",
            width: "100%",
            padding: "0.5rem",
            borderBottomLeftRadius: "0.33rem",
            borderBottomRightRadius: "0.33rem",
          }}
        >
          SOC 2
        </Typography>
      </HoverBox>

      <HoverBox
        component="a"
        href="https://aws.amazon.com/compliance/iso-27001-faqs/"
        target="_blank"
      >
        <Box flex="1" display="flex" alignItems="center">
          <SvgLogo src="/img/HomePage/iso-icon.svg" alt="ISO 27001" />
        </Box>
        <Typography
          variant="h6"
          mt={1}
          sx={{
            backgroundColor: "#e5e7eb",
            width: "100%",
            padding: "0.5rem",
            borderBottomLeftRadius: "0.33rem",
            borderBottomRightRadius: "0.33rem",
          }}
        >
          ISO 27001
        </Typography>
      </HoverBox>
    </HoverBoxContainer>
  );
};

export default SecurityBox;
