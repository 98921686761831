import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles"; // Add this import
import PropTypes from "prop-types";
import theme from "./theme";
import ContactForm from "./ContactForm";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const LowBalanceDialog = ({ open, onClose, setLowBalanceOpen }) => {
  const [formOpen, setFormOpen] = useState(false);
  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            border: "0.3rem solid #ddd", // Set the border width and color
            borderRadius: "0.5rem",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            borderBottom: "0.1rem solid #ddd",
            padding: "0.5rem 1.5rem",
            marginBottom: "1rem",
            backgroundColor: "#f0f0f2",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Low Balance</span>
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography>
            You have exhausted your balance. Please top up your account to
            continue using the service.
            <br />
            <br />
            To add balance, click on the the profile picture icon in the top
            right corner and then click on TOP UP button.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleOpenForm}
            sx={{ width: "10rem" }}
          >
            Contact Sales
          </Button>
          <ContactForm open={formOpen} handleClose={handleCloseForm} />
          {/* <Button
            variant="contained"
            sx={{
              color: "#fff",
            }}
            onClick={() => setLowBalanceOpen(false)}
            color="primary"
          >
            Top Up
          </Button> */}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

LowBalanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setLowBalanceOpen: PropTypes.func.isRequired,
};

export default LowBalanceDialog;
