import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles"; // Add this import
import PropTypes from "prop-types";
import theme from "./theme";
import ContactForm from "./ContactForm";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const LimitReachedDialog = ({ open, onClose, onSignIn }) => {
  const [formOpen, setFormOpen] = useState(false);
  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            border: "0.3rem solid #ddd", // Set the border width and color
            borderRadius: "0.5rem",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            borderBottom: "0.1rem solid #ddd",
            padding: "0.5rem 1.5rem",
            marginBottom: "1rem",
            backgroundColor: "#f0f0f2",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Free Prompts Exhausted</span>
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography>
            You have exhausted your free prompts. Please sign in to your account
            to continue or sign up to receive 10 additional free prompts.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleOpenForm}
            sx={{ width: "10rem" }}
          >
            Contact Sales
          </Button>
          <ContactForm open={formOpen} handleClose={handleCloseForm} />
          <Button
            variant="contained"
            sx={{
              color: "#fff",
            }}
            onClick={onSignIn}
            color="primary"
          >
            Sign in / Sign Up
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

LimitReachedDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired,
};

export default LimitReachedDialog;
