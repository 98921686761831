import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import {
  Box,
  Drawer,
  List,
  ListItem,
  IconButton,
  Button, // Import Button
} from "@mui/material"; // Import IconButton
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import Typography from "@mui/material/Typography";

const CustomDrawer = ({ drawerOpen, toggleDrawer, handleOpenForm }) => {
  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        },
      }}
    >
      <Box
        sx={{
          width: 250,
        }} // Add border radius
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {" "}
        {/* Add Agentik logo and name */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "1rem",
            paddingTop: "0.5rem",
          }}
        >
          <Box
            className="logo-company_name"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src="/logo512.png"
              alt="Logo"
              className="logo"
              style={{ width: "40px", height: "40px", marginRight: "0.5rem" }}
            />
          </Box>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
        <List>
          <ListItem component={Link} to="/home">
            <Link to="/home" sx={{ textDecoration: "none" }}>
              <Typography variant="h10" className="nav-btn btn-active">
                Home
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            component={Link}
            to={{ pathname: "/capital-insights" }}
            target="_blank"
          >
            <Link
              to={{ pathname: "/capital-insights" }}
              target="_blank"
              sx={{ textDecoration: "none" }}
            >
              <Typography variant="h10" className="nav-btn">
                Capital Insights
              </Typography>
            </Link>
          </ListItem>
          <ListItem>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleOpenForm}
              sx={{ width: "100%", marginTop: "0.75rem" }}
            >
              Contact Sales
            </Button>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

// Define prop types
CustomDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
};

export default CustomDrawer;
