import { v4 as uuidv4 } from "uuid";
import { base_URL } from "../apiURLs";

export const updateNonRegisteredUsageTracker = (noOfPrompts = 0) => {
  const visitor = localStorage.getItem("user");
  let visitorIdentifier;
  let promptsUsed = 0;
  const currentDateTime = new Date(
    new Date().getTime() + 5.5 * 60 * 60 * 1000
  ).toISOString();
  console.log(visitor);
  if (
    visitor !== "null" &&
    visitor !== "undefined" &&
    visitor !== null &&
    visitor !== undefined
  ) {
    const visitorDetails = JSON.parse(visitor);
    visitorIdentifier =
      visitorDetails.visitorIdentifier || visitorDetails.userId;
    promptsUsed = visitorDetails.promptsUsed + noOfPrompts;
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...visitorDetails,
        promptsUsed: visitorDetails.promptsUsed + noOfPrompts,
      })
    );
  } else {
    visitorIdentifier = uuidv4();
    localStorage.setItem(
      "user",
      JSON.stringify({
        visitorIdentifier,
        firstVisit: new Date(
          new Date().getTime() + 5.5 * 60 * 60 * 1000
        ).toISOString(),
        promptsUsed: 0,
        userType: "UNREGISTERED",
      })
    );
  }

  //Send the identifier to the backend
  fetch(`${base_URL}/user/update_or_create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      visitorIdentifier,
      firstVisit: currentDateTime,
      promptsUsed,
      userType: "UNREGISTERED",
    }),
  });
};

export const fetchNonRegisteredUsageTracker = async (visitorIdentifier) => {
  try {
    const response = await fetch(`${base_URL}/user/${visitorIdentifier}`);
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    console.log(data);
    return data; // Return the fetched data
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null; // Return null or handle the error as needed
  }
};

export const updateRegisteredUser = (isUserSignedIn, loggedInUser) => {
  if (isUserSignedIn && loggedInUser) {
    fetch(`${base_URL}/user/update_or_create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loggedInUser),
    });
  }
};
