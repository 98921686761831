import React, { useState } from "react";
import PropTypes from "prop-types"; // Add this import
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment"; // Add this import
import Chip from "@mui/material/Chip"; // Add this import
import { base_URL } from "../apiURLs";

const RazorpayButton = ({ setUserBalance, userBalance, loggedInUser }) => {
  // Add userBalance to props
  const [amount, setAmount] = useState(50); // Default amount
  const [showInput, setShowInput] = useState(false);

  const handlePayment = async () => {
    if (amount < 5) {
      alert("Minimum amount is INR 5");
      return;
    }

    try {
      console.log("Creating order...");
      // Create order on the server and fetch the order_id
      const {
        data: { order_id, amount: orderAmount },
      } = await axios.post(`${base_URL}/create-order`, {
        amount,
      });

      console.log("Order created:", order_id, orderAmount);

      const options = {
        key: "rzp_live_1k8INHMeKPWwyZ", // Replace with your Razorpay API key
        amount: orderAmount * 100, // Razorpay expects the amount in paise (multiply by 100)
        currency: "INR",
        order_id: order_id, // Pass the order_id from the backend
        name: "Agentik",
        description: "Capital Insights",
        image: "/logo192.png",
        handler: function (response) {
          console.log("Payment successful:", response);
          setUserBalance(parseFloat(userBalance) + parseFloat(orderAmount)); // Use userBalance from props, convert to integers
          // Handle the success response from Razorpay
          const paymentDetails = {
            payment_id: response.razorpay_payment_id,
            order_id: response.razorpay_order_id,
            signature: response.razorpay_signature,
            amount: orderAmount, // Pass the amount back to the server
            status: "success", // You can customize this based on Razorpay's response
            user_id: loggedInUser.userId,
          };

          // Send the payment details to your backend
          axios
            .post(`${base_URL}/payment-status`, paymentDetails)
            .then((res) => {
              console.log("Payment status updated:", res.data);
            })
            .catch((err) => {
              console.error("Error updating payment status:", err);
            });
        },
        // prefill: {
        //   name: loggedInUser.userId,
        //   email: loggedInUser.signInDetails.loginId,
        //   // contact: "9999999999",
        // },
        theme: {
          color: "#0082fb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <Box>
      <Button
        id="pl_Ot8yJvZ1xARBB1"
        variant={showInput ? "text" : "contained"}
        style={{ color: showInput ? "#44475b" : "#fff" }}
        onClick={() => setShowInput(true)}
      >
        Top up
      </Button>
      {showInput && (
        <Box mt={2}>
          <TextField
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            inputProps={{ min: 5 }}
            fullWidth
            variant="outlined"
            label="Enter amount"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
            helperText={amount < 5 ? "Amount must be at least ₹5" : ""}
          />
          {/* Chips for predefined amounts */}
          <Box mt={2} display="flex" gap={1}>
            <Chip label="₹50" onClick={() => setAmount(50)} />
            <Chip label="₹500" onClick={() => setAmount(500)} />
            <Chip label="₹5,000" onClick={() => setAmount(5000)} />
          </Box>
          <Button
            onClick={handlePayment}
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              color: "#fff",
            }}
          >
            Proceed to pay
          </Button>
        </Box>
      )}
    </Box>
  );
};

RazorpayButton.propTypes = {
  setUserBalance: PropTypes.func.isRequired, // Add prop types validation
  userBalance: PropTypes.number.isRequired, // Add prop types validation
  loggedInUser: PropTypes.shape({
    userId: PropTypes.string,
  }).isRequired,
};

export default RazorpayButton;

/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_Ot8yJvZ1xARBB1" async> </script> </form> */
