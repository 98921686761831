import React from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme"; // Adjust the import path as necessary
import { styled } from "@mui/material/styles";

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "&.Mui-expanded": {
    minHeight: "unset",
  },
}));

const InstructionsBox = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          lineHeight: 1.2,
          backgroundColor: "#fff",
          // padding: "0.25rem 0.5rem",
          padding: { xs: "0.25rem 0rem", sm: "0.25rem 0.5rem" },
          borderBottomLeftRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: { xs: "0.5rem", md: 0 },
          borderBottomRightRadius: { xs: "0.5rem", md: 0 },
          boxShadow: "rgba(0, 0, 0, 0.1) 0 0 1.5rem 0",
          height: "100%",
          overflowY: "auto",
        }}
        spacing={1}
        direction="row"
      >
        <Grid item xs={12}>
          <Typography variant="h10" display="block" marginBottom="0.5rem">
            How to use the tool?
          </Typography>
          <Typography variant="instructions">
            <span>
              Generate interactive charts or get direct answers by simply
              providing natural language prompts. Following data is available
              for analysis:
              <br />
              <div style={{ margin: "1rem 0", marginRight: "1rem" }}>
                <Accordion>
                  <CustomAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      minHeight: "40px",
                      "& .MuiAccordionSummary-content": { margin: "10px 0" },
                    }}
                  >
                    <strong
                      style={{ color: "#0082fb", fontFamily: "Quicksand" }}
                    >
                      Equity
                    </strong>
                  </CustomAccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0 }}>
                    <ul>
                      <li>
                        <strong>Daily</strong>: Open, High, Low, Close, Adj
                        Close Price, Traded Volume, P/E Ratio, P/B Ratio, PEG
                        Ratio
                      </li>
                      <li>
                        <strong>Quarterly</strong>: Income Statements,
                        Shareholding Pattern, Earnings Per Share (EPS), Dividend
                        Yield
                      </li>
                      <li>
                        <strong>Trailing Twelve Months (TTM)</strong>: Income
                        Statements
                      </li>
                      <li>
                        <strong>Annual</strong>: Income Statements, Balance
                        Sheets, Cash Flow Statements, Financial Ratios -
                        Profitability, Liquidity & Valuation
                      </li>
                      <li>
                        <strong>Latest</strong>: Market Capitalization, Sector,
                        Industry, Large/Mid/Small Cap Categorization
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <CustomAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      minHeight: "40px",
                      "& .MuiAccordionSummary-content": { margin: "10px 0" },
                    }}
                  >
                    <strong
                      style={{ color: "#0082fb", fontFamily: "Quicksand" }}
                    >
                      Benchmark Indices
                    </strong>
                  </CustomAccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0 }}>
                    <ul>
                      <li>
                        <strong>Daily</strong>: Closing Index Value, P/E Ratio,
                        P/B ratio, Dividend Yield for 35+ NIFTY Benchmark
                        Indices since Jan 2000
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <CustomAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      minHeight: "40px",
                      "& .MuiAccordionSummary-content": { margin: "10px 0" },
                    }}
                  >
                    <strong
                      style={{ color: "#0082fb", fontFamily: "Quicksand" }}
                    >
                      Mutual Funds
                    </strong>
                  </CustomAccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0 }}>
                    <ul>
                      <li>
                        <strong>Daily</strong>: Net Asset Value (NAV)
                      </li>
                      <li>
                        <strong>Latest</strong>: Category, Fund House
                      </li>
                    </ul>
                    for Growth Option of all open-ended Mutual Fund schemes
                    since Apr 2006
                  </AccordionDetails>
                </Accordion>
              </div>
              <strong>Steps:</strong>
              <ol>
                <li>Enter your prompt in the input box</li>
                <li>
                  Click on VISUALIZE for an interactive chart or CHAT to get
                  direct answers
                </li>
                <li>Download or share results</li>
                <li>
                  Refine your prompt if needed to get more accurate results
                </li>
              </ol>
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h10">Best Practices for Prompting</Typography>
          <Typography variant="instructions">
            <ul>
              <li>
                <strong>Be Specific:</strong> Specify the exact data you need,
                e.g., &quot;Show the closing index values of NIFTY 50 for the
                month of August 2024&quot; instead of &quot;Show NIFTY 50
                data.&quot;
              </li>
              <li>
                <strong>Use Full Sentences:</strong> Write complete sentences
                for better accuracy, e.g., &quot;What is the latest PE ratio of
                NIFTY MIDCAP 150 Index?&quot;
              </li>
              <li>
                <strong>Include Date Ranges:</strong> If you need data for a
                specific period, mention the start and end dates, e.g.,
                &quot;Show the NAV of Quant Flexicap Fund Direct Plan from
                January 2023 to July 2023.&quot;
              </li>
              <li>
                <strong>Combine Metrics:</strong> You can request multiple
                metrics in one prompt, e.g., &quot;Compare the closing index
                value of NIFTY 50 index with TATAMOTORS close price for the year
                2023.&quot;
              </li>
              <li>
                <strong>Ask Questions:</strong> Frame your prompt as a question
                for better context, e.g., &quot;What was the closing value of
                the NIFTY 50 index on August 1, 2024?&quot;
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default InstructionsBox;
