import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { base_URL } from "../apiURLs";

function ContactForm({ open, handleClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    more: "",
  });
  const [errors, setErrors] = useState({ email: false });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ email: !formData.email });

    if (!formData.email) return;

    try {
      await axios.post(`${base_URL}/contact`, formData);
      alert("Form submitted successfully!");
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          border: "0.3rem solid #ddd", // Set the border width and color
          borderRadius: "0.5rem",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "2rem",
          borderBottom: "0.1rem solid #ddd",
          padding: "0.5rem 1.5rem",
          marginBottom: "1rem",
          backgroundColor: "#f0f0f2",
        }}
      >
        Contact Sales
        <IconButton
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "0.9375rem",
            right: "0.9375rem",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "1rem",
            borderBottom: "0.05rem solid #ddd",
            paddingBottom: "0.5rem",
          }}
        >
          Tell us about your requirement and our team will reach out to you.
          <br />
          <span
            style={{
              color: "#1452f1",
              fontSize: "0.75rem",
              fontWeight: 500,
              letterSpacing: "0.05rem",
            }}
          >
            *REQUIRED
          </span>
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              name="name"
              label="Name"
              placeholder="Name"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="email"
              label={
                <span>
                  Email
                  <span
                    style={{
                      color: "#1452f1",
                      fontWeight: 500,
                    }}
                  >
                    *
                  </span>
                </span>
              }
              placeholder="Email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email && "Email is required"}
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="phone"
              label="Phone"
              placeholder="Phone"
              fullWidth
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="company"
              label="Organisation"
              placeholder="Organisation"
              fullWidth
              variant="outlined"
              value={formData.company}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="more"
              label="Your message"
              placeholder="Your message"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={formData.more}
              onChange={handleChange}
            />
          </Box>
          <DialogActions sx={{ paddingRight: 0 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: "#fff",
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

// Define prop types
ContactForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ContactForm;
