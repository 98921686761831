import React from "react";

const GovernanceImage = () => {
  const boxStyle = {
    display: "inline-block",
    overflow: "hidden",
    width: "100%", // Adjust to your desired size
    // height: "200px", // Adjust to your desired size
    transition: "transform 0.5s ease, background-color 0.3s ease",
    position: "relative", // To position the image inside the box
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Light background to reveal color transition
    paddingRight: "1rem", // Space around the image
    borderRadius: "0.5rem",
    border: "0.15rem solid #e5e7eb",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensure the image covers the box without stretching
    filter: "grayscale(100%)",
    transition: "filter 0.5s ease",
    borderRadius: "8px", // Match the box's border-radius
  };

  const boxHoverStyle = {
    transform: "scale(1.03)", // Slight pop-up effect
    backgroundColor: "#fff", // Change background color to white on hover
  };

  const imageHoverStyle = {
    filter: "grayscale(0%)", // Remove greyscale on hover
  };

  return (
    <div
      style={boxStyle}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = boxHoverStyle.transform;
        e.currentTarget.style.backgroundColor = boxHoverStyle.backgroundColor;
        e.currentTarget.querySelector("img").style.filter =
          imageHoverStyle.filter;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)"; // Reset to original size
        e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.5)"; // Reset background color
        e.currentTarget.querySelector("img").style.filter = "grayscale(100%)"; // Reset filter
      }}
    >
      <img
        src="/img/HomePage/governance.png"
        alt="Descriptive Alt Text"
        style={imageStyle}
      />
    </div>
  );
};

export default GovernanceImage;
