import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";

const AuthenticatorDialog = ({ open, onClose, initialState }) => {
  return (
    <>
      <style>
        {`
          [data-amplify-authenticator] {
            --amplify-components-button-primary-background-color: #0082fb;
            --amplify-components-button-primary-hover-background-color: #0066c8;
            --amplify-components-tabs-item-active-border-color: #0082fb;
            --amplify-components-tabs-item-active-color: #0082fb;
            --amplify-components-tabs-item-hover-color: #0066c8;
            --amplify-components-button-link-color: #0082fb;
            --amplify-components-button-link-hover-color: #0066c8;
          }
        `}
      </style>
      <Dialog open={open} onClose={onClose}>
        <Authenticator
          initialState={initialState}
          services={{
            socialProviders: ["google"],
          }}
          components={{
            SignUp: {
              formFields: [
                {
                  type: "email",
                  required: true,
                },
              ],
            },
          }}
        />
      </Dialog>
    </>
  );
};

AuthenticatorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialState: PropTypes.string,
};

export default AuthenticatorDialog;
